<template>
  <div>
    <base-header class="pb-6">
      <b-row align-v="center" class="py-4">
        <b-col lg="6" cols="7">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block">
            <route-bread-crumb :crumbs='generateBreadCrumb()'></route-bread-crumb>
          </nav>
        </b-col>
      </b-row>
    </base-header>

    <b-container fluid class="mt--6">
      <b-overlay :show="isBusy">
        <b-card>
          <validation-observer v-slot="{ handleSubmit }" ref="formValidator">
            <div v-if="!isUpdate()">
              <h6 class="heading-small text-muted mb-4">Media Information</h6>

             

              <file-input
              rules="required"
                :label="'Image'"
                :name="'imageFile'"
                :required="true"
                v-on:change="fileInputChanged"
              ></file-input>
            </div>
            <div v-else>
              <b-row>
                <b-col lg="12" class="d-flex justify-content-center">
                  <b-img
                    style="max-height: 400px"
                    thumbnail
                    class="mb-4 align-self-center"
                    :src="getSourceFromFile(mediaForm.file.filename, 'medium')"
                    alt="Image 1"
                  ></b-img>
                </b-col>
              </b-row>
            </div>
            <b-form
              class="needs-validation"
              @submit.prevent="handleSubmit(mediaFormSubmit)"
              novalidate
            >
              <div class="">
                <b-row>
                  <b-col lg="6">
                    <base-input
                      type="text"
                      :name="'Name'"
                      label="Name"
                      :required="true"
                      placeholder="Name"
                      v-model="mediaForm.name"
                    >
                    </base-input>
                  </b-col>
                  <b-col lg="6">
                     <base-input
                      type="select"
                      :required="true"
                      label="Format"
                      :name="'Format'"
                      placeholder="Format"
                      v-model="mediaForm.format"
                      :options="formats"
                      :key-field="'value'" 
                      :value-field="'value'"
                      :text-field="'name'"
                    ></base-input>
                  </b-col>
                  <b-col lg="6">
                    <b-form-group>
                      <label class="form-control-label">Tags</label>
                      <b-form-tags
                        input-id="tags-basic"
                        v-model="mediaForm.tags"
                      ></b-form-tags>
                    </b-form-group>
                  </b-col>
                  <b-col lg="6">
                     <label class="form-control-label">&nbsp;</label>
                    <b-form-checkbox class="mb-3" v-model="mediaForm.visible">
                      Visible
                    </b-form-checkbox>
                  </b-col>
                </b-row>
              </div>

              <base-button type="primary" native-type="submit" class="my-4">
                {{ isUpdate() ? "Update" : "Create" }}
              </base-button>
            </b-form>
          </validation-observer>
        </b-card>
      </b-overlay>
    </b-container>
  </div>
</template>
<script>
//  import { Dropdown, DropdownItem, DropdownMenu, Table, TableColumn } from 'element-ui';
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import { fullname } from "@/util/fullname";
import { capitalize } from "@/util/capitalize";
import * as mediaRepo from "@/api-repo/media";
import FileInput from "@/components/Inputs/FileInput.vue";
import { getSourceFromFile } from "@/util/imageSource";
//import { ValidationProvider } from "vee-validate";
import { handleError, successMessage } from "@/util/error-handler";
export default {
  inject: ["userStore"],
  components: {
    RouteBreadCrumb,
    FileInput,
   // ValidationProvider,
  },
  data() {
    return {
      isBusy: false,
      file: null,
      formats: [
        {name: 'Document', value: 'document'},
        {name: 'Image', value: 'image'},
      ],
      mediaForm: {
        format: null,
        file: null,
        name: null,
        tags: [],
        _id: null,
        folder: null,
        visible: false,
      },
    };
  },
  created() {
    this.getInitData();
    if (this.isUpdate()) {
      this.getData();
    }
  },
  methods: {
     
    fullname,
    capitalize,
    getSourceFromFile,
    getInitData: async function () {},
    generateBreadCrumb() {
        return [{label: "Media", route: "Media"},{label: this.isUpdate() ? this.mediaForm.name : "Add New Media"}];
      },
    fileInputChanged: function (e) {
      console.log(e[0]);
      this.file = e[0];
    },
    getData: async function () {
      try {
        this.isBusy = true;
        let result = await mediaRepo.getById(
          this.$route.params.id,
          this.userStore.state.token
        );
        this.mediaForm.name = result.data.data.name;
        this.mediaForm.file = result.data.data.file;
        this.mediaForm.visible = result.data.data.visible || false;
        this.mediaForm.tags = result.data.data.tags;
        this.mediaForm.format= result.data.data.format;
      } catch (e) {
        handleError(e);
      } finally {
        this.isBusy = false;
      }
    },
    isUpdate: function () {
      return this.$route.params.id != null;
    },
    mediaFormSubmit: async function () {
      const isValid = await this.$refs.formValidator.validate();
      if (isValid) {
        if (!this.isUpdate()) {
          try {
            this.isBusy = true;
            await mediaRepo.upload(
              this.file,
              this.file.name,
              {
                format: this.mediaForm.format,
                name: this.mediaForm.name,
                tags: this.mediaForm.tags,
                visible: this.mediaForm.visible || false,
              },
              this.userStore.state.token
            );
            successMessage("Media created successfully");
            this.$router.replace({ path: "/media" });
          } catch (e) {
            handleError(e);
          } finally {
            this.isBusy = false;
          }
        } else {
          try {
            this.isBusy = true;
            await mediaRepo.update(
              this.$route.params.id,
              {
                format: this.mediaForm.format,
                name: this.mediaForm.name,
                tags: this.mediaForm.tags,
                visible: this.mediaForm.visible || false,
              },
              this.userStore.state.token
            );
            successMessage("User updated successfully");
          } catch (e) {
            handleError(e);
          } finally {
            this.isBusy = false;
          }
        }
      }
    },
  },
};
</script>
